import { EDITTRUCK_PERMISSION, EDITTARE_PERMISSION, DELETE_PERMISSION, GROWERS_TRUCKS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Trucks",
      "actions-width": 110,
      "search-by": "Code,Description,TruckType",
      "ph-search-by": "Search by Truck Code, Truck Description, Truck Type"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record, toggleActive = _ref.toggleActive;
        return [_vm.hasEditTruckPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Edit Trucks")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "mr-2",
          attrs: {
            "icon": "edit",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1) : _vm._e(), _vm.hasEditTarePermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Edit Tares")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "mr-2",
          attrs: {
            "icon": "dashboard",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.redirectUrl(record);
            }
          }
        })], 1) : _vm._e(), _vm.hasDeletePermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "list__action-toggle-active",
          attrs: {
            "icon": record.isInactive ? "eye-invisible" : "eye",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return toggleActive(record);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  }, [_c("text-field", {
    key: "Code",
    attrs: {
      "data-index": "code",
      "title": "Truck Code",
      "sorter": true
    }
  }), _c("text-field", {
    key: "Description",
    attrs: {
      "data-index": "description",
      "title": "Truck Description"
    }
  }), _c("text-field", {
    key: "Tare",
    attrs: {
      "data-index": "tare",
      "title": "Tare"
    }
  }), _c("icon-field", {
    key: "ZeroWeightAllowed",
    attrs: {
      "title": "Zero Weight Allowed",
      "align": "center",
      "data-index": "zeroWeightAllowed",
      "render-icon": _vm.renderIcon,
      "width": 150
    }
  }), _c("text-field", {
    key: "TruckType",
    attrs: {
      "data-index": "truckType",
      "title": "Truck Type"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListTrucks",
  inject: ["can"],
  computed: {
    hasEditTruckPermission() {
      return this.can(EDITTRUCK_PERMISSION);
    },
    hasEditTarePermission() {
      return this.can(EDITTARE_PERMISSION);
    },
    hasDeletePermission() {
      return this.can(DELETE_PERMISSION);
    }
  },
  methods: {
    renderIcon(value) {
      if (value == 0) {
        return "";
      }
      return "check-circle";
    },
    redirectUrl(value) {
      this.$router.push(`/watties-grower/trucks/${value.id}/tare`);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListTrucks = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-truck"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.trucks",
      "api-url": _vm.apiUrl,
      "create-route": "/watties-grower/trucks/create",
      "edit-route": "/watties-grower/trucks/:id",
      "page": _vm.page
    }
  }, [_c("list-trucks")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "Trucks",
  components: {
    ListTrucks
  },
  data() {
    return {
      ListTrucks,
      apiUrl,
      page: GROWERS_TRUCKS,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "truck",
          title: "Trucks",
          path: "/watties-grower/trucks"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
